import { render, staticRenderFns } from "./flow.vue?vue&type=template&id=3508cce8&scoped=true&"
import script from "./flow.vue?vue&type=script&lang=js&"
export * from "./flow.vue?vue&type=script&lang=js&"
import style0 from "./flow.vue?vue&type=style&index=0&id=3508cce8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3508cce8",
  null
  
)

export default component.exports
import {QCard,QInnerLoading,QSpinnerAudio,QCardSection,QBtnGroup,QBtn,QToggle,QAvatar,QPagination} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QInnerLoading,QSpinnerAudio,QCardSection,QBtnGroup,QBtn,QToggle,QAvatar,QPagination})
